import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: 'none',
      },
      colorInherit: {
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
    MuiFormControl: {
      root: {
        marginLeft: '4px',
        marginRight: '4px',
      },
    },
    MuiListItemIcon: {
      root: {
        marginLeft: '8px',
        marginRight: '8px',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '8px',
      },
    },
    MuiSnackbarContent: {
      root: {
        flexWrap: 'nowrap',
      },
    },
  },
  palette: {
    action: {
      selected: 'rgba(100, 149, 237, 0.24)',
    },
    primary: {
      contrastText: '#fff',
      dark: '#2668ba',
      light: '#9ac5ff',
      main: '#6495ed',
    },
    secondary: {
      contrastText: '#fff',
      dark: '#00827b',
      light: '#64e5dc',
      main: '#20b2aa',
    },
  },
  shadows: [
    'none',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
  ],
  typography: {
    button: {
      textTransform: 'none',
    },
    overline: {
      textTransform: 'none',
    },
  },
});

export default theme;
